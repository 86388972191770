<template>
    <page-content title="历史数据">

        <common-table
                ref="table"
                path="web/iot/water/history"
                date-range
                :queryParams="queryParams"
                :columns="columns">

            <template slot="search">
                <a-button @click="$refs.table.export('历史数据')">导出Excel</a-button>
            </template>

            <template slot="logo" slot-scope="{record}">
                <a-avatar shape="square" :size="30" :src="record.avatarUrl">{{record.nickName}}</a-avatar>
            </template>

            <template slot="operation" slot-scope="{record}">
                <action-view  @click="view(record)"></action-view>
            </template>

        </common-table>


    </page-content>
</template>
<script>
    export default {

        data () {
            return {
                queryParams:{
                    deviceId:this.$route.params.deviceId
                }
            }
        },

        computed: {
            columns () {
                return [
                    {
                        title: '时间',
                        dataIndex: 'createdAt',
                        sorter:true
                    },
                    {
                        title: 'PH',
                        dataIndex: 'ph',
                        sorter:true
                    },
                    {
                        title: 'ORP(mv)',
                        dataIndex: 'orp',
                        sorter:true
                    },
                    {
                        title: '余氯(mg/L)',
                        dataIndex: 'cl',
                        sorter:true
                    },
                    {
                        title: '温度(℃)',
                        dataIndex: 'temp',
                        sorter:true
                    },
                    {
                        title: '浊度(mg/L)',
                        dataIndex: 'turbirdity',
                        sorter:true
                    },
                ]
            }
        },
        methods: {
            view (record) {
                this.$router.push('/device/camera/'+record.id)
            },

            getList () {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

</style>
